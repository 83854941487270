import React, { useState } from 'react';
import PropTypes from "prop-types"
import { Link } from 'react-router-dom';
import { useDispatch } from "react-redux"

import {
  Col,
  Row,
} from "reactstrap";


const Pagination = props => {
  const dispatch = useDispatch()

  const [itemPaginator, setItemPaginator] = useState([1, 2, 3, 4, 5])
  const count = parseInt(props.count)
  const limit = props.limit
  const pages = Math.ceil(count / limit)
  const additionalParam = props.additionalParam

  let next = props.next
  let previous = props.previous
  let offset, page, search

  if(next){
    let parameters = new URL(next)
    offset = parseInt(parameters.searchParams.get('offset'))
    search = parameters.searchParams.get('search')
    page = offset / limit
  }
  else {
    offset = pages
    page = pages
  }

  if(previous){
    let parameters = new URL(previous)
    search = parameters.searchParams.get('search')
  }

  const paginationHandler = url => {
    console.log(url);
    let path = url.split(props.baseUrl)
    dispatch(props.filterFunction(path[1]))
  }

  const pageChange = (page) => {
    let url;
    if (search) {
      url = `?limit=${limit}&offset=${page * limit}&search=${search}&${additionalParam}`;
    } else {
      url = `?limit=${limit}&offset=${page * limit}&${additionalParam}`;
    }
    dispatch(props.filterFunction(url));
  };
  

  if (page) {
    if (page > itemPaginator[itemPaginator.length - 1]) {
      setItemPaginator(
        itemPaginator.map((item) => (
          item + itemPaginator.length
        ))
      )
    }
  }

  return (
    <Row>
      <Col sm="6">
        <p>Página {page} de {pages}</p>
      </Col>
      <Col sm="6">
        <ul className="pagination pagination-rounded justify-content-end">
          <li className={previous ? "page-item visible" : "page-item disabled invisible"}>
            <Link
              className="page-link"
              to="#"
              aria-label="Previous"
              onClick={() => pageChange(0)}
            >
              <i className="mdi mdi-chevron-double-left"></i>
            </Link>
          </li>
          <li className={previous ? "page-item" : "page-item disabled"}>
            <Link
              className="page-link"
              to="#"
              aria-label="Previous"
              onClick={() => paginationHandler(previous)}
            >
              <i className="mdi mdi-chevron-left"></i>
            </Link>
          </li>
          {itemPaginator.map((item, idx) => (
            item <= pages &&
            <li key={idx} className={item === page ? "page-item active" : "page-item"}>
              <Link className="page-link" to="#" onClick={() => pageChange(item - 1)}>
                {item}
              </Link>
            </li>
          ))}
          <li className={next ? "page-item" : "page-item disabled"}>
            <Link
              className="page-link"
              to="#"
              aria-label="Next"
              onClick={() => paginationHandler(next)}
            >
              <i className="mdi mdi-chevron-right"></i>
            </Link>
          </li>
          <li className={next ? "page-item visible" : "page-item disabled invisible"}>
            <Link
              className="page-link"
              to="#"
              aria-label="Next"
              onClick={() => pageChange(pages-1)}
            >
              <i className="mdi mdi-chevron-double-right"></i>
            </Link>
          </li>
        </ul>
      </Col>
    </Row>
  );
}

Pagination.propTypes = {
  count: PropTypes.number,
  next: PropTypes.string,
  previous: PropTypes.string,
  limit: PropTypes.number,
  baseUrl: PropTypes.string,
  filterFunction: PropTypes.func,  
}

export default Pagination;