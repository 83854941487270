import axios from "axios";

const URL = process.env.REACT_APP_API_URL;

const axiosApi = axios.create({
  baseURL: URL,
})

const token = localStorage.getItem('token');
if (token) {
  axiosApi.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

let refresh = false;

axiosApi.interceptors.response.use(
  (resp) => resp,
  async (error) => {    
    if (error.config.url !== "/auth/login/") {
      if (error.response.status === 401 && !refresh) {
        refresh = true;
        try {
          const res = await axios.post(
            `${URL}/auth/token/refresh/`,
            {},
            { withCredentials: true }
          );
          if (res.status === 200) {
            axiosApi.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${res.data.ccaess}`;
            refresh = false;
            error.config.headers["Authorization"] = `Bearer ${res.data.access}`;
            return axiosApi(error.config);
          }
        } catch (error) {
          localStorage.removeItem("user");
          window.location.href = "/login";
        }
      }
    }
    return error.response;
  }
);


export const get = async (url) => {
  const res = await axiosApi.get(url);
  return res;
};

export const post = async (url, data = {}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
  const res = await axiosApi.post(url, data, config);
  return res;
};

export const patch = async (url, data = {}) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
  const res = await axiosApi.patch(url, data, config);
  return res;
};

export const put = async (url, data) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };
  const res = await axiosApi.put(url, data, config);
  return res;
};



export const del = async (url) => {
  const res = await axiosApi.delete(url);
  return res;
};
export default axiosApi;