import React, { useEffect, useState } from 'react';
import { Modal, Row, Col, Input, Label, Button, Form } from 'reactstrap';
import { getCalificacionContactoforCampaign, getCampaignFullList, getContactsFullList, getDescripcionCalificacion, postContactsCreate, postGestionContactsCreate, postLeadsStatusChange } from '../../../helpers/backend';
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const ManagementManual = ({ isOpen, onClose, isLead, contactId, leadId  }) => {
  const [alert, setAlert] = useState(false);
  const [close, setClose] = useState(false);
  const [status, setStatus] = useState(false);
  const [disableContact, setDisableContact] = useState(false);
  const [message, setMessage] = useState("");
  const [contacts, setContacts] = useState([]);
  const [calificacionList, setCalificacionList] = useState(null);
  const [descripcionList, setDescripcionList] = useState([]);
  const [showNewContactFields, setShowNewContactFields] = useState(false);
  const [campaignData, setCampaignData] = useState({});
  const [gestionId, setGestionId] = useState(null)
  const [validationErrors, setValidationErrors] = useState({
    phone: false,
    email: false,
    calificacion: false,
    descripcion: false,
  });
  const [validationErrors2, setValidationErrors2] = useState({
    calificacion: false,
    descripcion: false,
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const ciudadOptions = [
    { label: 'Bogotá', value: 'BOGOTA' },
    { label: 'Bucaramanga', value: 'BUCARAMANGA' }
  ];


  const [formData, setFormData] = useState({
    calificacion: "",
    descripcion: "",
    observaciones: "",
    contacto: "",
    nombre: "",
    apellido: "",
    phone: "",
    email: "",
    sala_ventas: " ",
    placa: " ",
    campaign: [],
    fecha_proximo_contacto: null,
    hora_proximo_contacto: null,
  });


  const { contacto, nombre, apellido, phone, email, calificacion, descripcion, observaciones, sala_ventas, placa, campaign, fecha_proximo_contacto, hora_proximo_contacto } = formData;
  const validateForm = () => {
    let errors = {
      phone: !formData.phone,
      email: !emailRegex.test(formData.email),
    };    
    setValidationErrors(errors);
    
    // Solo devolverá true si no hay errores
    return !Object.values(errors).some(Boolean);
  };
  const validateForm2 = () => {
    let errors = {
      calificacion: !formData.calificacion,
      descripcion: !formData.descripcion,
    };    
    setValidationErrors2(errors);
    
    // Solo devolverá true si no hay errores
    return !Object.values(errors).some(Boolean);
  };
  const getContactsData = async () => {
    const response = await getContactsFullList();
    setContacts(response.data);
  };

  const getCalificacionData = async (id) => {
    const response = await getCalificacionContactoforCampaign(id);
    setCalificacionList(response.data);
  };

  const getDescripcionData = async (id) => {
    const response = await getDescripcionCalificacion(id);
    setDescripcionList(response.data);
  };

  const getCampaignData = async () => {
    const response = await getCampaignFullList();
    setCampaignData(response.data);
  };
  useEffect(() => {
    getContactsData();
    getCampaignData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(contactId){
      setFormData({ ...formData, contacto: contactId });
      setDisableContact(true)
    }
    // eslint-disable-next-line
  }, [contactId]);

  useEffect(() => {
    if (formData.calificacion) {
      getDescripcionData(formData.calificacion);
    }
  }, [formData.calificacion]);
  
  useEffect(() => {
    if (formData.campaign) {
      getCalificacionData(formData.campaign);
    }
  }, [formData.campaign]);

  const toggleNewContactFields = () => setShowNewContactFields(!showNewContactFields);

  const handleSaveNewContact = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Validación exitosa, guardando contacto...');
      const response = await postContactsCreate({
        first_name: nombre,
        last_name: apellido,
        email: email,
        phone: phone,
      });
      console.log(response);
      setStatus(response.status);
      setAlert(true);
      setMessage(response.data.message);
      if (response.status === 201) {
        const newContact = response.data;
        setContacts([...contacts, newContact]);
        setFormData({ ...formData, contacto: newContact.id });
      }
    } else {
      console.log('Validación fallida, no se guarda el contacto.');
    }
  };
  

  const onSubmit = async () => {
    if (!validateForm2()) {
      setAlert(true);
      setMessage('Por favor, complete todos los campos obligatorios.');
      return;
    }
    let dataToSend = {
      contacto: contacto,
      calificacion: calificacion,
      descripcion: descripcion,
      observaciones: observaciones,
      estado: "FINALIZADO",
      sala_ventas: sala_ventas,
      placa: placa,
      campaign: campaign,
      is_leads: isLead
    };  
    // Agregar fecha_proximo_contacto y hora_proximo_contacto solo si no son null
    if (fecha_proximo_contacto !== null) {
      dataToSend.fecha_proximo_contacto = fecha_proximo_contacto;
    }
    if (hora_proximo_contacto !== null) {
      dataToSend.hora_proximo_contacto = hora_proximo_contacto;
    }
  
    // Realizar la llamada API con el objeto construido
    const response = await postGestionContactsCreate(dataToSend);
console.log(response);
    setStatus(response.status);
    setClose(true)
    setMessage(response.data.message);
    setGestionId(response.data.id)
  };
  
  const FinalizarGestion = async () => {
    const response = await postLeadsStatusChange(leadId, {
      accion: "FINALIZAR",
      gestion: gestionId
    })
    console.log(response);
  }

  return (
    <>
      {(status === 201 || status === 200) && alert ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false)
            setShowNewContactFields(false);
          }}
        >
          {message}
        </SweetAlert>
      ) : null}
      {(status === 201 || status === 200) && close ? (
        <SweetAlert
          title="Hecho!"
          success
          timeout={2000}
          showConfirm={false}
          onConfirm={() => { 
            FinalizarGestion()
            setClose(false)
            onClose()            
          }}
        >
          {message}
        </SweetAlert>
      ) : null}
      {(status !== 201 && status !== 200) && alert ? (
        <SweetAlert
          title="Error"
          error
          timeout={2000}
          showConfirm={false}
          onConfirm={() => {
            setAlert(false);
          }}
        >
          {message}
        </SweetAlert>
      ) : null}
      <Modal isOpen={isOpen} toggle={onClose} size="lg">
        <Row>
          <h4 className="text-primary p-3 ms-2"><b>Gestión Manual</b></h4>
        </Row>
        <Row className='p-1'>
          <Row className="justify-content-center">
            <Col lg={8} className="mb-3">
              <Label for="contacto" className="form-label">Contacto</Label>
              <div className="d-flex">
                <Select
                  id="contacto"
                  name="contacto"
                  placeholder="Seleccione"
                  isDisabled={showNewContactFields || disableContact}
                  value={contacts.find(c => c.id === contacto) ? { label: `${contacts.find(c => c.id === contacto).phone[0]?.telefono || ''} - ${contacts.find(c => c.id === contacto).get_full_name}`, value: contacto } : null}
                  onChange={(item) => setFormData({
                    ...formData,
                    contacto: item.value,
                  })}
                  options={contacts.map(item => ({
                    label: `${item.phone && item.phone[0] ? item.phone[0].telefono : ''} - ${item.get_full_name}`,
                    value: item.id
                  }))}
                  menuPosition="fixed"
                  className="flex-grow-1 me-2"
                />
                <Button color="primary" onClick={toggleNewContactFields}>+</Button>
              </div>
            </Col>
          </Row>
          {showNewContactFields && (
            <>
              <Form onSubmit={handleSaveNewContact}>
                <Row className='d-flex justify-content-center'>
                  <Col lg={5} className="mb-3">
                    <Label for="nombre" className="form-label">Nombre</Label>
                    <Input
                      id="nombre"
                      name="nombre"
                      required
                      onChange={(e) => setFormData({ ...formData, nombre: e.target.value })}
                    />
                  </Col>
                  <Col lg={5} className="mb-3">
                    <Label for="apellido" className="form-label">Apellido</Label>
                    <Input
                      id="apellido"
                      name="apellido"
                      required
                      onChange={(e) => setFormData({ ...formData, apellido: e.target.value })}
                    />
                  </Col>
                </Row>
                <Row className='d-flex justify-content-center'>
                  <Col lg={5} className="mb-3">
                    <Label for="email" className="form-label">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      type="email"
                      required
                      onChange={(e) => {
                        setFormData({ ...formData, email: e.target.value });
                        setValidationErrors({ ...validationErrors, email: false });
                      }}                      
                      isInvalid={validationErrors.email}
                    />
                    {validationErrors.email && <div className="text-danger">El correo electrónico es obligatorio y debe tener un formato válido</div>}
                  </Col>
                  <Col lg={5} className="mb-3">
                    <Label for="phone" className="form-label">Teléfono</Label>
                    <PhoneInput
                      inputProps={{
                        name: 'phone',
                        required: true,
                      }}
                      id="phone"
                      name="phone"
                      country={'co'}
                      value={phone}                      
                      onChange={(item) => { setFormData({ ...formData, phone: item }); setValidationErrors({ phone: false }); }}
                      isInvalid={validationErrors.phone}
                    />
                    {validationErrors.phone && <div className="text-danger">El teléfono es obligatorio</div>}
                  </Col>
                </Row>
                <Row className="justify-content-center mb-3">
                  <Col lg={4} className="d-flex justify-content-center">
                    <Button color="secondary" className='text-dark me-1' onClick={toggleNewContactFields}>Cancelar</Button>
                    <Button color="primary" type="submit">Guardar</Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
          {!showNewContactFields && (
            <>
              <Row className="justify-content-center">
                <Col lg={8} className="mb-3">
                  <Label for="campaign" className="form-label">Campaña</Label>
                  <Select
                    options={Array.isArray(campaignData)
                      ? campaignData.map((item) => ({
                        label: item.name,
                        value: item.id,
                      }))
                      : []}
                      onChange={(selectedOptions) => {
                        const selectedContactData = selectedOptions.map(
                          (option) => option.value
                        );
                        setFormData({
                          ...formData,
                          campaign: selectedContactData,
                        });
                      }}
                    placeholder={"Seleccione"}
                    isMulti={true}
                    menuPosition="fixed"
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={4} className="mb-3">
                  <Label for="calificacion" className="form-label">Resultados</Label>
                  <Select
                    id="calificacion"
                    name="calificacion"
                    placeholder="Seleccione"
                    onChange={(item) => {setFormData({
                      ...formData,
                      calificacion: item.value,
                    });  setValidationErrors({calificacion: false})}}
                    options={Array.isArray(calificacionList) ? calificacionList.map(item => ({ label: item.name, value: item.id })): []}
                    menuPosition="fixed"
                    isInvalid={validationErrors2.calificacion}
                    />
                    {validationErrors2.calificacion && <div className="text-danger">La calificación es obligatoria</div>}
                  </Col>
                <Col lg={4} className="mb-3">
                  <Label for="descripcion" className="form-label">Calificación</Label>
                  <Select
                    id="descripcion"
                    name="descripcion"
                    placeholder="Seleccione"
                    options={Array.isArray(descripcionList) ? descripcionList.map(item => ({ label: item.name, value: item.id })) : []}
                    onChange={(item) => {setFormData({
                      ...formData,
                      descripcion: item.value,
                    });  setValidationErrors({descripcion: false})}}
                    menuPosition="fixed"
                    isInvalid={validationErrors2.descripcion}
                    />
                    {validationErrors2.descripcion && <div className="text-danger">La descripción es obligatoria</div>}
                  </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={4} className="mb-3">
                  <Label for="calificacion" className="form-label">Ciudad</Label>
                  <Select
                    placeholder="Seleccione"
                    options={ciudadOptions}
                    onChange={(item) => setFormData({
                      ...formData,
                      sala_ventas: item.value,
                    })
                    }
                    menuPosition="fixed"
                  />
                </Col>
                <Col lg={4} className="mb-3">
                  <Label for="descripcion" className="form-label">Placa</Label>
                  <Input
                    id="observaciones"
                    name="observaciones"
                    type="text"
                    onChange={(e) => setFormData({ ...formData, placa: e.target.value })}
                    onInput={(e) => e.target.value = e.target.value.toUpperCase()}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={8} className="mb-3">
                  <Label for="observaciones" className="form-label">Observaciones</Label>
                  <Input
                    id="observaciones"
                    name="observaciones"
                    type="textarea"
                    onChange={(e) => setFormData({ ...formData, observaciones: e.target.value })}
                  />
                </Col>
              </Row>
              <Row className="justify-content-center mx-1">
                <p className='p-2 bg-secondary'> <b>Próximo Contacto (Opcional)</b></p>
              </Row>
              <Row className="justify-content-center">
                <Col lg={4} className="mb-3">
                  <Label for="date" className="form-label">Fecha</Label>
                  <Input
                    id="date"
                    name="date"
                    type="date"
                    onChange={(e) => {                      
                      setFormData({ ...formData, fecha_proximo_contacto: e.target.value });
                    }}
                  />
                </Col>
                <Col lg={4} className="mb-3">
                  <Label for="time" className="form-label">Hora</Label>
                  <Input
                    id="time"
                    name="time"
                    type="time"
                    onChange={(e) => {
                      // Para el campo de tiempo, ya está en el formato correcto según el mensaje de error
                      setFormData({ ...formData, hora_proximo_contacto: e.target.value });
                    }}
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mb-3">
                <Col lg={4} className="d-flex justify-content-center">
                  <Button color="secondary" className='text-dark me-1' onClick={onClose}>Cerrar </Button>
                  <Button color="primary" onClick={onSubmit}>Guardar</Button>
                </Col>
              </Row>
            </>
          )}
        </Row>
      </Modal>
    </>
  );
};

export default ManagementManual;
