import { get, patch, put, post, del } from "./api";
import * as url from "./url";

//dashboard
export const getDashboard = () => get(url.DASHBOARD);

// Authentication
export const postLogin = (data) => post(url.LOGIN, data);
export const getMe = () => get(url.ME);
export const postLogout = () => post(url.LOGOUT);

//Rol
export const postRolCreate = (data) => post(url.ROL, data);
export const getRolList = () => get(url.ROL);
export const getRolRead = (id) => get(`${url.ROL}${id}/`);
export const getRolFilter = (changeUrl) =>
  get(`${url.ROL}${changeUrl.changeUrl}`);
export const getRolFullList = () => get(url.ROL_FULLLIST);
export const patchRolUpdate = (id, data) =>
  patch(`${url.ROL}${id.id}/`, data);
export const deleteRol = (id) => del(`${url.ROL}${id}/`);
export const postPermissionToGroup = (data) => post(url.PERMISSION_GROUP, data);

//USER
export const postUserCreate = (data) => post(url.USER, data);
export const putUpdatePassword = (id, data) => put(`${url.USER}${id.id}/update_password/`, data);
export const getUserList = () => get(url.USER);
export const getUserRead = (id) => get(`${url.USER}${id}/`);
export const getUserFilter = (changeUrl) =>
  get(`${url.USER}${changeUrl.changeUrl}`);
export const getUserFullList = () => get(url.USER_FULLLIST);
export const patchUserUpdate = (id, data) =>
  patch(`${url.USER}${id.id}/`, data);
export const deleteUser = (id) => del(`${url.USER}${id}/`);
export const getPermission = () => get(url.PERMISSION);
export const postPermissionToUser = (data) => post(url.PERMISSION_USER, data);
export const patchPermissionToUser = (id, data) => patch(`${url.USER}${id}/add_permissions_to_user/`, data);


//ACCOUNT
export const postAccountCreate = (data) => post(url.ACCOUNT, data);
export const getAccountList = () => get(url.ACCOUNT);
export const getAccountRead = (id) => get(`${url.ACCOUNT}${id}/`);
export const getAccountFilter = (changeUrl) =>
  get(`${url.ACCOUNT}${changeUrl.changeUrl}`);
export const getAccountFullList = () => get(url.ACCOUNT_FULLLIST);
export const patchAccountUpdate = (id, data) =>
  patch(`${url.ACCOUNT}${id.id}/`, data);

//CAMPAIGN
export const postCampaignCreate = (data) => post(`${url.CAMPAIGN}create_campaign/`, data);
export const getCampaignList = () => get(url.CAMPAIGN);
export const getCampaignRead = (id) => get(`${url.CAMPAIGN}${id}/`);
export const getCampaignFilter = (changeUrl) =>

  get(`${url.CAMPAIGN}${changeUrl.changeUrl}`);
export const deleteCampaign = (id) => del(`${url.CAMPAIGN}${id}/`);
export const getCampaignFullList = () => get(url.CAMPAIGN_FULLLIST);
export const patchCampaignUpdate = (id, data) =>
  patch(`${url.CAMPAIGN}${id.id}/`, data);
export const getContactsOfCampaign = (id) => get(`${url.CONTACTS}get_contact_campaign/?campaign=${id}`);
export const getEncargadosOfCampaign = (id) => get(`${url.CONTACTS}get_encargados_campaign/?campaign=${id}`);
export const postContactsOfCampaign = (id, data) => post(`${url.CAMPAIGN}${id}/assign_contacts/`, data);
export const postRemoveContactsOfCampaign = (id, data) => post(`${url.CAMPAIGN}${id}/remove_contacts/`, data);
export const postEncargadoOfCampaign = (id, data) => post(`${url.CAMPAIGN}${id}/add_encargados/`, data);
export const postRemoveEncargadosOfCampaign = (id, data) => post(`${url.CAMPAIGN}${id}/remove_encargados/`, data);


//AUDITS
export const postAuditsCreate = (data) => post(url.AUDITS, data);
export const getAuditsList = () => get(url.AUDITS);
export const getAuditsRead = (id) => get(`${url.AUDITS}${id}/`);
export const getAuditsFilter = (changeUrl) =>
  get(`${url.AUDITS}${changeUrl.changeUrl}`);
export const getAuditsFullList = () => get(url.AUDITS_FULLLIST);
export const patchAuditsUpdate = (id, data) =>
  patch(`${url.AUDITS}${id.id}/`, data);

//LEADS
export const postLeadsCreate = (data) => post(url.LEADS, data);
export const getLeadsList = () => get(url.LEADS);
export const getLeadsFinishedList = () => get(`${url.LEADS}leads_finalizados/`);
export const getLeadsRead = (id) => get(`${url.LEADS}${id}/`);
export const getLeadsFilter = (changeUrl) =>
  get(`${url.LEADS}${changeUrl.changeUrl}`);
export const getLeadsFullList = () => get(url.LEADS_FULLLIST);
export const patchLeadsUpdate = (id, data) =>
  patch(`${url.LEADS}${id.id}/`, data);
export const postExcelLeads = (data) => post(`${url.LEADS}/upload_programacion/`, data);
export const postLeadsStatusChange = (id, data) => post(`${url.LEADS}${id}/cambiar_estado/`, data);
export const postLandingPage = (data) => post(`/leads/event/`, data);
export const postBroadcastCampaign = (data) => post(`/leads/campaign_broadcasts/`, data);
export const getBroadcastCampaign = () => get(`/leads/campaign_broadcasts/`);
export const getBroadcastCampaignFilter = (changeUrl) => get(`/leads/campaign_broadcasts/${changeUrl}`);
export const postBroadcastMessage = (data) => post(`/leads/campaign_broadcasts/difusion/`, data);
export const postBroadcastConfirmation = (data) => post(`/leads/campaign_broadcasts/send_confirmacion_servicio/`, data);


//CONTACTS
export const postContactsCreate = (data) => post(url.CONTACTS, data);
export const postGestionContactsCreate = (data) => post(`/contact/gestionContacto/`, data);
export const getContactsList = () => get(url.CONTACTS);
export const getContactsWitUser = () => get(`${url.CONTACTS}get_contact_with_user/`);
export const getContactsRead = (id) => get(`${url.CONTACTS}${id}/`);
export const getContactsFilter = (changeUrl) =>
  get(`${url.CONTACTS}${changeUrl.changeUrl}`);
export const getContactsFullList = () => get(url.CONTACTS_FULLLIST);
export const patchContactsUpdate = (id, data) =>
  patch(`${url.CONTACTS}${id.id}/`, data);
export const deleteContacts = (id) => del(`${url.CONTACTS}${id}/`);
export const getAgent = () => get(url.CONTACTS_AGENT);
export const getSupervisor = () => get(`${url.CONTACTS}get_contact_supervisor/`);
export const getAgentOfCampaing = (id) => get(`${url.CONTACTS}get_contact_campaign_agent/?campaign=${id}`);
export const getDataUser = () => get(`${url.CONTACTS}get_data_user`);
export const postRelacion = (id, data) => post(`${url.CONTACTS}${id}/crear_relacion/`, data);
export const postUpdateChatContact = (id, data) => post(`${url.CONTACTS}${id}/registrar_contacto/`, data);
export const getReportSupervisor = () => get(`${url.CONTACTS}reporte_supervisor/`);
export const getReportStatus = () => get(`${url.CONTACTS}reporte_status/`);

//Grupo Contacto
export const getGrupoContacto = () => get(`/contact/grupoContacto/`);
export const getGrupoContactoFilter = (changeUrl) => get(`/contact/grupoContacto/${changeUrl}`);
export const postGrupoContacto = (data) => post(`/contact/grupoContacto/`, data);
export const postGrupoAddContacto = (id, data) => post(`/contact/grupoContacto/${id}/agregar_contactos/`, data);
export const postGrupoRemoveContacto = (id, data) => post(`/contact/grupoContacto/${id}/remover_contactos/`, data);
export const postGrupoExcel = (data) => post(`/contact/grupoContacto/carga_masiva/`, data);
export const postGrupoContactoAdd = (id, data) => post(`/contact/contacto/${id}/agregar_grupo/`, data);
export const postGrupoContactoRemove = (id, data) => post(`/contact/contacto/${id}/remover_grupo/`, data);


//CONTACT_DATA
export const postContactsDataCreate = (data) => post(url.CONTACTS_DATA, data);
export const getContactsDataList = () => get(url.CONTACTS_DATA);
export const getContactsDataRead = (id) => get(`${url.CONTACTS_DATA}${id}/`);
export const getContactsDataFilter = (changeUrl) =>
  get(`${url.CONTACTS_DATA}${changeUrl.changeUrl}`);
export const getContactsDataFullList = () => get(url.CONTACTS_DATA_FULLLIST);
export const patchContactsDataUpdate = (id, data) =>
  patch(`${url.CONTACTS_DATA}${id.id}/`, data);
export const deleteContactsData = (id) => del(`${url.CONTACTS_DATA}${id}/`);
export const getContactsDataPhone = (id) => get(`${url.CONTACTS_DATA}get_datos_telefono/?contact=${id}`);
export const getContactsDataMail = (id) => get(`${url.CONTACTS_DATA}get_datos_email/?contact=${id}`);
export const getContactsDataAddress = (id) => get(`${url.CONTACTS_DATA}get_datos_direccion/?contact=${id}`);
export const getContactsSocialData = (id) => get(`${url.CONTACTS_DATA}get_datos_redes_sociales/?contact=${id}`);

//CONTACTS_DOMCUMENT
export const postContactsDocumentCreate = (data) => post(url.CONTACTS_DOCUMENT, data);
export const getContactsDocumentList = () => get(url.CONTACTS_DOCUMENT);
export const getContactsDocument = (id) => get(`${url.CONTACTS_DOCUMENT}get_document_contact/?contact=${id}`);
export const getContactsDocumentFullList = () => get(url.CONTACTS_DOCUMENT_FULLLIST);
export const getContactsDocumentRead = (id) => get(`${url.CONTACTS_DOCUMENT}${id}/`);
export const getContactsDocumentFilter = (changeUrl) =>
  get(`${url.CONTACTS_DOCUMENT}${changeUrl}`);
export const patchContactsDocumentUpdate = (id, data) =>
  patch(`${url.CONTACTS_DOCUMENT}${id.id}/`, data);
export const deleteContactsDocument = (id) => del(`${url.CONTACTS_DOCUMENT}${id}/`);

//GESTION CONTACTO
export const getCalificacionContactoList = () => get(url.CALIFICACION_CONTACTO);
export const getCalificacionContactoFullList = () => get(url.CALIFICACION_CONTACTO_LISTA);
export const getCalificacionContactoforCampaign = (id) => get(`${url.CALIFICACION_CONTACTO}get_campaign/?campaign=${id}`);
export const getChangeCalificacionContactoList = (changeUrl) => get(`${url.CALIFICACION_CONTACTO}${changeUrl.changeUrl}`);
export const patchCalificacionContactoUpdate = (id, data) =>
  patch(`${url.CALIFICACION_CONTACTO}${id}/`, data);
export const getCalificacionContactoRead = (id) => get(`${url.CALIFICACION_CONTACTO}${id}/`);
export const postCalificacionContactoAdd = (data) => post(url.CALIFICACION_CONTACTO, data);
export const deleteCalificacionContact = (id) => del(`${url.CALIFICACION_CONTACTO}${id}/`);

export const getTipoGestionContactoList = () => get(url.TIPO_GESTION_CONTACTO);
export const getTipoGestionContactoFullList = () => get(url.TIPO_GESTION_CONTACTO_LISTA);
export const getChangeTipoGestionContactoList = (changeUrl) => get(`${url.TIPO_GESTION_CONTACTO}${changeUrl.changeUrl}`);
export const patchTipoGestionContactoUpdate = (id, data) =>
  patch(`${url.TIPO_GESTION_CONTACTO}${id}/`, data);
export const getTipoGestionContactoRead = (id) => get(`${url.TIPO_GESTION_CONTACTO}${id}/`);
export const postTipoGestionContactoAdd = (data) => post(url.TIPO_GESTION_CONTACTO, data);

export const getDescripcionCalificacionList = () => get(url.DESCRIPCION_CALIFICACION);
export const getDescripcionCalificacionFullList = () => get(url.DESCRIPCION_CALIFICACION_LISTA);
export const getChangeDescripcionCalificacionList = (changeUrl) => get(`${url.DESCRIPCION_CALIFICACION}${changeUrl.changeUrl}`);
export const postDescripcionCalificacionAdd = (data) => post(url.DESCRIPCION_CALIFICACION, data);
export const patchDescripcionCalificacionUpdate = (id, data) =>
  patch(`${url.DESCRIPCION_CALIFICACION}${id}/`, data);
export const getDescripcionCalificacionRead = (id) => get(`${url.DESCRIPCION_CALIFICACION}${id}/`);
export const getDescripcionCalificacion = (id) => get(`${url.DESCRIPCION_CALIFICACION}get_descripcion?calificacion=${id}`);

export const postGestionesContactoCreate = (data) => post(url.GESTION_CONTACTO, data);
export const getGestionesContactoList = () => get(url.GESTION_CONTACTO);
export const getGestionesContactoRead = (id) => get(`${url.GESTION_CONTACTO}${id}/`);
export const getGestionesContactoFilter = (changeUrl) =>
  get(`${url.GESTION_CONTACTO}${changeUrl.changeUrl}`);
export const getGestionesContactoFilterAsync = (changeUrl) =>
  get(`/contact/gestionContacto/get_gestiones_contacto/${changeUrl}`);
export const getGestionesContactoFullList = () => get(url.GESTION_CONTACTO_FULLLIST);
export const patchGestionesContactoUpdate = (id, data) =>
  patch(`${url.GESTION_CONTACTO}${id.id}/`, data);
export const getContactAgain = (filter) => get(`${url.GESTION_CONTACTO}proximo_contacto/?filtro=${filter}`);

export const getReportsForHourList = (changeUrl) => get(`${url.GESTION_CONTACTO}gestiones_por_hora/${changeUrl}`);
export const getReportsBot = (changeUrl) => get(`${url.GESTION_CONTACTO}gestiones_por_bot/${changeUrl}`);
export const getReportsForTimeList = (changeUrl) => get(`${url.GESTION_CONTACTO}tiempo_promedio_atencion/${changeUrl}`);
export const getReportsExcel = (changeUrl) => get(`${url.GESTION_CONTACTO}exportar-excel/${changeUrl}`);

//Hoja de Vida
export const getHojaContacto = (id) => get(`${url.HOJA_CONTACTO}get_hoja_de_vida/?contacto=${id}`);
export const postHojaContacto = (data) => post(url.HOJA_CONTACTO, data);
export const postExperienciaHoja = (data) => post(url.EXPERIENCIA_HOJA, data);
export const postEducacionHoja = (data) => post(url.EDUCACION_HOJA, data);
export const postConocimientoHoja = (data) => post(url.CONOCIMIENTO_HOJA, data);

//CALENDAR
export const postCalendarEvent = (data) => post(url.CALENDAR_EVENT, data);
export const getCalendarEventFullList = () => get(`${url.CALENDAR_EVENT}FullList`);
export const getCalendarEventRead = (id) => get(`${url.CALENDAR_EVENT}${id}`);
export const patchCalendarEvent = (id, data) => patch(`${url.CALENDAR_EVENT}${id}/`, data);
export const deleteCalendarEvent = (id) => del(`${url.CALENDAR_EVENT}${id}`);
export const getCalendarEventContact = (id) => get(`${url.CALENDAR_EVENT}get_event_contacto/?contacto=${id}`);
export const postGestionEvent = (data) => post(url.CALENDAR_GESTION, data);
export const getEventGestion = (id) => get(`${url.CALENDAR_GESTION}get_gestiones_event/?event=${id}`);

//CASOS
export const postCasosCreate = (data) => post(url.CASOS, data);
export const getCasosList = () => get(url.CASOS);
export const getCasosRead = (id) => get(`${url.CASOS}${id}/`);
export const getCasosEstados = (estado) => get(`${url.CASOS}casos_por_estado/?estado=${estado}`);
export const getCasosEstadosFilter = (estado, changeUrl) => get(`${url.CASOS}casos_por_estado/?estado=${estado}${changeUrl}`);
export const getCasosFilter = (changeUrl) =>
  get(`${url.CASOS}${changeUrl}`);
export const getCasosFullList = () => get(url.CASOS_FULLLIST);
export const patchCasosUpdate = (id, data) =>
  patch(`${url.CASOS}${id.id}/`, data);
export const postCasosStatusChange = (id, data) => post(`${url.CASOS}${id}/cambiar_estado/`, data);
export const postCasoUpdateContacto = (id, data) =>
  post(`${url.CASOS}${id}/asignar_contacto/`, data);

//TASKS
export const postTasksCreate = (data) => post(url.TASKS, data);
export const getTasksFullList = () => get(`${url.TASKS}FullList`);

//Survey
export const postSurveyCreate = (data) => post(url.SURVEY, data);
export const getSurveyList = () => get(url.SURVEY);
export const getSurveyRead = (id) => get(`${url.SURVEY}${id}/`);
export const getSurveyFilter = (changeUrl) =>
  get(`${url.SURVEY}${changeUrl.changeUrl}`);
export const getSurveyFullList = () => get(url.SURVEY);
export const patchSurveyUpdate = (id, data) =>
  patch(`${url.SURVEY}${id.id}/`, data);
export const postSurveyQuestion = (data) => post(url.SURVEY_QUESTION, data);
export const getSurveyFull = (id) => get(`${url.SURVEY_QUESTION}?survey_id=${id}`);
export const postSurveyAssign = (data) => post(url.SURVEY_ASSIGN, data);

//Chat
export const getAllChat = (number) => get(`${url.WHATHSAPP_CHAT}?phone_number=${number}`);
export const postWhatsappMessage = (data) => post("sendMessage/", data);
export const postPdfMasive = (data) => post("whatsapp/conversation/send_masiva/", data);
export const postTelegramMessage = (data) => post("telegram/sendMessage/", data);
export const getWhatsappPriority = () => get("whatsapp/prioridad");
export const patchWhatsappPriority = (id, data) => patch(`whatsapp/prioridad/${id}/`, data);
export const getWhatsappAtajos = () => get("whatsapp/respuesta_rapida/FullList");
export const postWhatsappAtajos = (data) => post("whatsapp/respuesta_rapida/", data);
export const getTemplates = () => get("whatsapp/template/");
export const getTemplatesFilter = (changeUrl) => get(`whatsapp/template/${changeUrl}`);
export const postTemplate = (data) => post("whatsapp/template/", data);

//Conversations
export const getNewConversations = () => get(`${url.CONVERSATIONS}get_conversation_new`);
export const getNewConversationsFilter = (changeUrl) => get(`${url.CONVERSATIONS}get_conversation_new/${changeUrl}`);
export const getConversationsGestion = () => get(`${url.CONVERSATIONS}get_conversation_gestion`);
export const getConversationsGestionFilter = (changeUrl) => get(`${url.CONVERSATIONS}get_conversation_gestion/${changeUrl}`);
export const postAssignConversation = (number) => post(`${url.CONVERSATIONS}${number}/assign_agent/`);
export const putGuardarGestion = (id, data) => put(`${url.GESTION_CONTACTO}guardar_gestion/?contacto=${id}`, data);
export const getGestionFinalizadas = (option) => get(`${url.GESTION_CONTACTO}get_gestion_finalizada/?frecuencia=${option}`);
export const getGestionPorContacto = (id) => get(`${url.GESTION_CONTACTO}get_gestiones_contacto?contact=${id}`);
export const postAddNotes = (number, note) => post(`${url.CONVERSATIONS}${number}/conversation_type_nota/?message_nota=${note}`);
export const postTransferAgent = (number, data) => post(`${url.CONVERSATIONS}${number}/transfer_conversation/`, data);
export const postTransferCampaign = (number, campaign, data) => post(`${url.CONVERSATIONS}${number}/transfer_conversation/?new_campaign=${campaign}`, data);
export const postCreateConversation = (data) => post(`${url.CONVERSATIONS}get_or_create_conversation/`,data);

//ISSABEL 
export const getIssabelReportList = () => get("issabel/call_records/");
export const getIssabelReportFilter = (change) => get(`issabel/call_records/${change}`);

export const getCallStart = (data) => post("issabel/make_call/", data);
export const getCallEnd = (data) => post("issabel/hangup/", data);

//TASK
export const getTask = (number) => get(`${url.TASKS}project/${number}/`);