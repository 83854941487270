import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from './constants';
import './TaskFlow.css';

const TaskCard = ({ task, index, columnIndex, deleteTask, openStepPanel }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.TASK,
    item: { task, index, columnIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={drag}
      className="task-card"
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={() => openStepPanel(task)} /* Panel se abre al hacer clic */
    >
      <p>{task.title}</p>
      <span className='delete-btn' onClick={(e) => { e.stopPropagation(); deleteTask(columnIndex, index); }}>×</span>
    </div>
  );
};

export default TaskCard;
