import React, { useState } from 'react';
import { useDrop } from 'react-dnd';
import TaskCard from './TaskCard';
import { ItemTypes } from './constants';
import './TaskFlow.css';
import { Input } from 'reactstrap';

const TaskColumn = ({ title, tasks, moveTask, deleteTask, columnIndex, deleteColumn, openStepPanel }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [columnTitle, setColumnTitle] = useState(title);

  const [, drop] = useDrop({
    accept: ItemTypes.TASK,
    drop: (item) => {
      moveTask(item.index, item.columnIndex, columnIndex);
    },
  });

  const handleEditTitle = () => {
    setIsEditing(!isEditing);
  };

  const handleTitleChange = (e) => {
    setColumnTitle(e.target.value);
  };

  return (
    <div
      ref={drop}
      className="task-column"
    >
      {isEditing ? (
        <Input value={columnTitle} onChange={handleTitleChange} onBlur={handleEditTitle} />
      ) : (
        <h5 className='text-dark' onDoubleClick={handleEditTitle}>{columnTitle}</h5>
      )}
      <span className='delete-btn' onClick={deleteColumn}>×</span>
      {tasks.map((task, index) => (
        <TaskCard
          key={task.id}
          task={task}
          index={index}
          columnIndex={columnIndex}
          deleteTask={deleteTask}
          openStepPanel={openStepPanel}
        />
      ))}
    </div>
  );
};

export default TaskColumn;
