import React, { useEffect, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TaskColumn from "./TaskColumn";
import TaskEditor from "./TaskEditor";
import StepPanel from "./StepPanel";
import "./TaskFlow.css";
import { MetaTags } from "react-meta-tags";
import { Button, Container } from "reactstrap";
import { getTask } from "../../helpers/backend";

const TaskFlow = () => {

  const [selectedTask, setSelectedTask] = useState(null);
  const [taskFlows, setTaskFlows] = useState([]);
  
  const getTaskList = async ()=> {
    const response =  await getTask(1)
    setTaskFlows(response.data.flow)
    console.log(response)
  }
  const moveTask = (taskIndex, fromColumnIndex, toColumnIndex) => {
    const newTaskFlows = [...taskFlows];
    const [movedTask] = newTaskFlows[fromColumnIndex].tasks.splice(
      taskIndex,
      1
    );
    newTaskFlows[toColumnIndex].tasks.push(movedTask);
    setTaskFlows(newTaskFlows);
  };

  const addTask = (taskTitle) => {
    const newTask = { id: Date.now(), title: taskTitle, steps: [] };
    const newTaskFlows = [...taskFlows];
    newTaskFlows[0].tasks.push(newTask);
    setTaskFlows(newTaskFlows);
  };

  const deleteTask = (columnIndex, taskIndex) => {
    const newTaskFlows = [...taskFlows];
    newTaskFlows[columnIndex].tasks.splice(taskIndex, 1);
    setTaskFlows(newTaskFlows);
  };

  const addColumn = (columnTitle) => {
    const newColumn = { id: Date.now(), title: columnTitle, tasks: [] };
    setTaskFlows([...taskFlows, newColumn]);
  };

  const deleteColumn = (columnIndex) => {
    const newTaskFlows = [...taskFlows];
    newTaskFlows.splice(columnIndex, 1);
    setTaskFlows(newTaskFlows);
  };

  const openStepPanel = (task) => {
    setSelectedTask(task);
  };

  const closeStepPanel = () => {
    setSelectedTask(null);
  };
  
  useEffect(() => {
    getTaskList()
  }, [])

  return (
    <React.Fragment>
      <div className={`page-content-task ${selectedTask ? "panel-open" : ""}`}>
        <MetaTags>
          <title>TrueContact | Tareas </title>
        </MetaTags>
        <Container fluid>
          <DndProvider backend={HTML5Backend}>
            <Button
              className="mb-1 btn-info"
              onClick={() => addColumn("Nuevo Flujo")}
            >
              Agregar Nuevo Flujo
            </Button>
            <TaskEditor onSave={addTask} />
            <div className="task-flow">
              <div
                className="task-columns mt-3"
                style={{ display: "flex", flexWrap: "wrap" }}
              >
                {Array.isArray(taskFlows) ? taskFlows.map((flow, index) => (
                  <TaskColumn
                    key={flow.id}
                    title={flow.title}
                    tasks={flow.tasks}
                    moveTask={moveTask}
                    deleteTask={deleteTask}
                    columnIndex={index}
                    deleteColumn={() => deleteColumn(index)}
                    openStepPanel={openStepPanel}
                  />
                )):[]}
              </div>
            </div>
          </DndProvider>
        </Container>
      </div>
      {selectedTask && (
        <div className="step-panel open">
          <StepPanel task={selectedTask} closePanel={closeStepPanel} />
        </div>
      )}
    </React.Fragment>
  );
};

export default TaskFlow;
