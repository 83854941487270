import { call, put, takeEvery } from "redux-saga/effects";

import {
  GESTIONES_CONTACTO_LIST,
  GESTIONES_CONTACTO_FILTER,
  GESTIONES_CONTACTO_CREATE,
  GESTIONES_CONTACTO_UPDATE,
} from "./actionTypes";

import {
 gestionesContactoListSuccess,
 gestionesContactoCreateSuccess,
 gestionesContactoUpdateSuccess,
 gestionesContactoRequestFailed,
} from "./actions";

import {
  getGestionesContactoList,
  getGestionesContactoFilter,
  postGestionesContactoCreate,
  patchGestionesContactoUpdate,
} from "../../helpers/backend";

function*gestionesContactoListSaga() {
  console.log("Trayendo lista de gestionesContacto...");
  try {
    const response = yield call(getGestionesContactoList);
    if (response.status === 200) {
      yield put(gestionesContactoListSuccess(response.data));
    } else {
      yield put(gestionesContactoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(gestionesContactoRequestFailed(error.response.data));
  }
}

function*gestionesContactoCreateSaga({ payload:gestionesContacto }) {
  console.log("Creando nuevo gestionesContacto...");
  try {
    const response = yield call(postGestionesContactoCreate,gestionesContacto);
    if (response.status === 201) {
      yield put(gestionesContactoCreateSuccess(response.data));
    } else {
      yield put(gestionesContactoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(gestionesContactoRequestFailed(error.response.data));
  }
}

function*gestionesContactoFilterSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getGestionesContactoFilter, { changeUrl });
    console.log(response)
    if (response.status === 200) {
      yield put(gestionesContactoListSuccess(response.data));
    } else {
      yield put(gestionesContactoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(gestionesContactoRequestFailed(error.response.data));
  }
}

function*gestionesContactoUpdateSaga({ payload: { id, data } }) {
  console.log("Actualizando datos del gestionesContacto...");
  try {
    const response = yield call(patchGestionesContactoUpdate, { id }, data);
    if (response.status === 200) {
      yield put(gestionesContactoUpdateSuccess(response.data));
    } else {
      yield put(gestionesContactoRequestFailed(response.data));
    }
  } catch (error) {
    yield put(gestionesContactoRequestFailed(error.response.data));
  }
}

export default function*gestionesContactoSaga() {
  yield takeEvery(GESTIONES_CONTACTO_LIST,gestionesContactoListSaga);
  yield takeEvery(GESTIONES_CONTACTO_FILTER,gestionesContactoFilterSaga);
  yield takeEvery(GESTIONES_CONTACTO_CREATE,gestionesContactoCreateSaga);
  yield takeEvery(GESTIONES_CONTACTO_UPDATE,gestionesContactoUpdateSaga);
}
