import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { CALIFICACION_CONTACTO_LIST,CALIFICACION_CONTACTO_LISTA,CALIFICACION_CONTACTO_ADD, CALIFICACION_CONTACTO_LIST_CHANGE } from "./actionTypes";
import {
  calificacionContactoListSuccess,
  calificacionContactoListFailed,
  calificacionContactoListaSuccess,
  calificacionContactoListaFailed,
  calificacionContactoAddSuccess,
  calificacionContactoAddFailed,


} from "./actions";

import {
  getCalificacionContactoList,
  getCalificacionContactoFullList,
  getChangeCalificacionContactoList,
  postCalificacionContactoAdd,

} from "../../helpers/backend";

function* calificacionContactoListChangeSaga({ payload: { changeUrl } }) {
  try {
    const response = yield call(getChangeCalificacionContactoList, { changeUrl });

    if (response.status === 200) {
      yield put(calificacionContactoListSuccess(response.data));
    } else {
      yield put(calificacionContactoListFailed(response.data));
    }
  } catch (error) {
    yield put(calificacionContactoListFailed(error.response.data));
  }
}
function* calificacionContactoListSaga() {
  try {
    const response = yield call(getCalificacionContactoList);

    if (response.status === 200) {
      yield put(calificacionContactoListSuccess(response.data));
    } else {
      yield put(calificacionContactoListFailed(response.data));
    }
  } catch (error) {
    yield put(calificacionContactoListFailed(error.response.data));
  }
}

function* calificacionContactoListaSaga() {
  try {
    const response = yield call(getCalificacionContactoFullList);

    if (response.status === 200) {
      yield put(calificacionContactoListaSuccess(response.data));
    } else {
      yield put(calificacionContactoListaFailed(response.data));
    }
  } catch (error) {
    yield put(calificacionContactoListaFailed(error.response.data));
  }
}

function* calificacionContactoAddSaga({ payload: { calificacionContacto } }) {
  try {
    const response = yield call(postCalificacionContactoAdd, {
      name: calificacionContacto.name,

    });

    if (response.status === 201) {
      yield put(calificacionContactoAddSuccess(response.data));
    } else {
      yield put(calificacionContactoAddFailed(response.data));
    }
  } catch (error) {
    yield put(calificacionContactoAddFailed(error.response.data));
  }
}



function* calificacionContactoSaga() {
  yield takeEvery(CALIFICACION_CONTACTO_LIST, calificacionContactoListSaga);
  yield takeEvery(CALIFICACION_CONTACTO_LISTA, calificacionContactoListaSaga);
  yield takeEvery(CALIFICACION_CONTACTO_ADD, calificacionContactoAddSaga);
  yield takeEvery(CALIFICACION_CONTACTO_LIST_CHANGE, calificacionContactoListChangeSaga);

}
  
export default calificacionContactoSaga;
