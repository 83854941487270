import React, { useEffect, useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';
import './ContactDirectory.css';
import { getContactsFullList, getGrupoContactoFilter } from '../helpers/backend';
import { Input } from 'reactstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const ContactDirectory = () => {
  const history = useHistory()
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(''); // id del grupo seleccionado
  const [contacts, setContacts] = useState([]);
  const [contactGroup, setContactGroup] = useState([]);

  // Obtener lista completa de contactos
  const getContactData = async () => {
    const response = await getContactsFullList();
    setContacts(response.data);
  };

  // Obtener lista de grupos de contacto
  const getContactGroup = async () => {
    try {
      const response = await getGrupoContactoFilter("FullList/");
      setContactGroup(response.data);
    } catch (error) {
      console.error('Error fetching contact group data', error);
    }
  };

  useEffect(() => {
    getContactData();
    getContactGroup();
  }, []);

  // Filtramos los contactos basados en el grupo seleccionado y el término de búsqueda
  const filteredContacts = Array.isArray(contacts) ? contacts.flatMap(contact => {
    return contact.phone.map(phone => ({
      ...contact,
      telefono: phone.telefono,
      telefono_mask: phone.telefono_mask
    }));
  }).filter(contact => {
    const matchSearch = contact.get_full_name.toLowerCase().includes(searchTerm.toLowerCase());

    // Comparamos el id del grupo seleccionado con el primer elemento del array dentro del atributo 'grupos'
    const matchGroup = selectedGroup
      ? contact.grupos.some(grupoArray => grupoArray[0] === parseInt(selectedGroup)) // Compara el id del grupo
      : true;

    return matchSearch && matchGroup;
  }) : [];

  return (
    <div className="contact-directory">
      <h2 className="title">Directorio de Contactos</h2>
      
      {/* Select de grupos */}
      <Input
        type="select"
        value={selectedGroup}
        onChange={(e) => setSelectedGroup(e.target.value)} // id del grupo seleccionado
        className="group-select"
      >
        <option value="">Todos los grupos</option>
        {Array.isArray(contactGroup) && contactGroup.map((item) => (
          <option key={item.id} value={item.id}> {/* El value es el id del grupo */}
            {item.name} {/* Muestras el nombre del grupo */}
          </option>
        ))}
      </Input>

      {/* Campo de búsqueda */}
      <Input
        type="text"
        className='mt-1 mb-1'
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Buscar contacto..."
      />

      {/* Lista de contactos */}
      <div className="contact-list">
        {filteredContacts && filteredContacts.map((contact, index) => (
          <div key={index} className="contact-card">
            <div className="contact-initials" style={{ backgroundColor: getRandomColor() }}>
              {contact.get_full_name.slice(0, 2).toUpperCase()}
            </div>
            <div className="contact-info">
              <div className="contact-details">
                <span className="contact-name-directory">{contact.get_full_name}</span>
                <span className="contact-phone">{contact.telefono}</span>
              </div>
              <FaWhatsapp 
                className="whatsapp-icon"                         
                onClick={() => {
                  window.focus();
                  history.replace({
                    pathname: '/chat',
                    state: {
                      selectedChatId: contact.telefono,
                      contactId: contact.id, 
                      type: "WhatsApp",
                    }
                  });
                }} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

// Función para generar colores aleatorios
const getRandomColor = () => {
  const colors = ['#f56a00', '#7265e6', '#ffbf00', '#00a2ae', '#ff4d4f', '#87d068'];
  return colors[Math.floor(Math.random() * colors.length)];
};

export default ContactDirectory;
