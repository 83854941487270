import React, { useState } from "react";
import "./TaskFlow.css";
import { FaCheck } from "react-icons/fa"; // Importa el ícono de check
import { Container } from "reactstrap";
import { BiSend } from "react-icons/bi";

const StepPanel = ({ task, closePanel }) => {
  const [steps, setSteps] = useState(task.steps || []);
  const [newStepTitle, setNewStepTitle] = useState("");
  const [newComment, setNewComment] = useState("");
  const [newLabelColor, setNewLabelColor] = useState("#FFCDD2");
  const [newDueDate, setNewDueDate] = useState(""); // Estado para la fecha de vencimiento
  const colorOptions = [
    "#FFEBEE",
    "#FCE4EC",
    "#F3E5F5",
    "#EDE7F6",
    "#E8EAF6",
    "#E3F2FD",
    "#E1F5FE",
    "#E0F7FA",
  ];

  const addStep = () => {
    const newStep = {
      id: Date.now(),
      title: newStepTitle,
      completed: false,
      comments: [],
      dueDate: newDueDate, // Asigna la fecha de vencimiento
      labels: [{ color: newLabelColor }],
    };
    setSteps([...steps, newStep]);
    setNewStepTitle("");
    setNewLabelColor("#FFCDD2");
    setNewDueDate(""); // Reinicia la fecha
  };

  const toggleStepCompletion = (stepId) => {
    setSteps(
      steps.map((step) =>
        step.id === stepId ? { ...step, completed: !step.completed } : step
      )
    );
  };

  const addComment = (stepId) => {
    if (newComment.trim()) {
      setSteps(
        steps.map((step) =>
          step.id === stepId
            ? {
                ...step,
                comments: [
                  ...step.comments,
                  { user: "Usuario", text: newComment },
                ],
              }
            : step
        )
      );
      setNewComment("");
    }
  };

  const handleDueDateChange = (e) => {
    setNewDueDate(e.target.value); // Actualiza la fecha de vencimiento
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="step-panel open">
          <div className="header">
            <h3>{task.title}</h3>
            <span className="close-btn" onClick={closePanel}>
              ✖
            </span>
          </div>
          <div className="new-step">
            <input
              type="text"
              value={newStepTitle}
              onChange={(e) => setNewStepTitle(e.target.value)}
              placeholder="Nuevo paso..."
            />
            <select
              value={newLabelColor}
              placeholder="Color"
              onChange={(e) => setNewLabelColor(e.target.value)}
            >
              <option disabled value="">
                Color
              </option>
              {colorOptions.map((color) => (
                <option
                  key={color}
                  value={color}
                  style={{ backgroundColor: color }}
                >
                  {" "}
                </option>
              ))}
            </select>
            <button className="add-btn" onClick={addStep}>
              <FaCheck />
            </button>
          </div>
          
          {/* Ahora colocamos el campo de fecha en una nueva línea */}
          <div className="due-date-container">
            <label className="due-date-label">Fecha de Vencimiento</label>
            <input
              type="date"
              value={newDueDate}
              onChange={handleDueDateChange} // Actualiza la fecha de vencimiento
            />
          </div>

          <ul>
            {steps.map((step) => (
              <li
                key={step.id}
                className={step.completed ? "completed" : ""}
                style={{ backgroundColor: step.labels[0].color }}
              >
                <span className="font-size-18 me-1 ">
                  <b>{step.title}</b>
                </span>
                <button
                  className="complete-btn"
                  onClick={() => toggleStepCompletion(step.id)}
                >
                  <FaCheck />
                </button>
                <div className="labels">
                  {step.labels.map((label, index) => (
                    <span
                      key={index}
                      className="label"
                      style={{ backgroundColor: label.color }}
                    ></span>
                  ))}
                </div>
                <div className="comments-section">
                  <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="Nuevo comentario..."
                  />
                  <button
                    className="add-comment-btn"
                    onClick={() => addComment(step.id)}
                  >
                    <BiSend />
                  </button>
                </div>
                <ul>
                  {step.comments.map((comment, index) => (
                    <li key={index}>
                      <strong>{comment.user}</strong>
                      <p>{comment.text}</p>
                    </li>
                  ))}
                </ul>
              </li>
            ))}
          </ul>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default StepPanel;
