import React, { useState } from 'react';
import './TaskFlow.css';
import { Input } from 'reactstrap';

const TaskEditor = ({ onSave }) => {
  const [taskTitle, setTaskTitle] = useState('');

  const handleSave = () => {
    onSave(taskTitle);
    setTaskTitle(''); // Limpiar el campo de entrada
  };

  return (
    <div className="task-editor">
      <Input
        type="text"
        value={taskTitle}
        onChange={(e) => setTaskTitle(e.target.value)}
        placeholder="Nueva tarea..."
        style={{ padding: '5px', marginRight: '10px' }}
      />
      <button onClick={handleSave}>Agregar Tarea</button>
    </div>
  );
};

export default TaskEditor;
